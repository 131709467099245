import React, { useState }  from "react"
import Form from 'react-bootstrap/Form';
import Layout from "../components/layout"
import SEO from "../components/seo"
import BloqueContacto from "../components/bloque-contacto"


const ContactoPage = () => {

	const [serverState, setServerState] = useState({
	  submitting: false,
	  status: null
	});

	const handleServerResponse = (ok, msg, form) => {
	  setServerState({
	    submitting: false,
	    status: { ok, msg }
	  });
	  if (ok) {
	    form.reset();
	  }
	};

	const handleOnSubmit = e => {
		e.preventDefault();
		const form = e.target;
		setServerState({ 
			submitting: true,
		});


		fetch("https://getform.io/f/cacb3b23-76be-430c-bb89-51d6b5b174ea", {
			method: 'POST',
			body: new FormData(form)
		}).then(r => {
          handleServerResponse(true, "¡Gracias por contactar con cocowat! En breve nos pondremos en contacto contigo.", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };


	return (
	  <Layout className="page-contacto">
	    <SEO title="Contacto" />

	    <section className="bloque bloque-main bloque-contacto-main">
			<div className="container">
			 	<h1 className="main-title">Contacto</h1>
			 	<p>¿Tienes un proyecto en mente y quieres que te ayudemos a tirarlo adelante? ¿Quieres saber más acerca de nuestros planes? Escríbenos y cuéntanos cositas.</p>
			</div>
	    </section>

		<section className="bloque-top container">
			<form onSubmit={handleOnSubmit} className="box">
				<div className="row">
					<div className="col-sm-6">
						<Form.Group controlId="nombre">
							<Form.Label className="label-required">Nombre</Form.Label>
							<Form.Control 
								name="nombre" 
								required
								placeholder="Escribe tu nombre (y apellidos)"
								/>
						</Form.Group>
					</div>
					<div className="col-sm-6">

					</div>		
					<div className="col-sm-6">
						<Form.Group controlId="email">
							<Form.Label className="label-required">Tu correo electrónico</Form.Label>
							<Form.Control 
								type="email"
								name="email" 
								required
								placeholder="Escribe tu e-mail"
								/>
						</Form.Group>
					</div>
					<div className="col-sm-6">
						<Form.Group controlId="telefono">
							<Form.Label>Teléfono</Form.Label>
							<Form.Control 
								type="text"
								name="telefono" 
								placeholder="Escribe tu teléfono"
								/>
						</Form.Group>
					</div>					
				</div>	
				<Form.Group controlId="asunto">
					<Form.Label className="label-required">Asunto</Form.Label>
					<Form.Control 
						type="text"
						name="asunto" 
						required
						placeholder="Escribe el asunto del mensaje"
						/>
				</Form.Group>
				<Form.Group controlId="descripcion">
					<Form.Label className="label-required">Descripción</Form.Label>
					<Form.Control 
						type="text"
						name="descripcion" 
						as="textarea"
						required
						placeholder="Escribe el cuerpo de tu mensaje"
						/>			
				</Form.Group>

				<button 
					type="submit" 
					className="btn btn-accent"
					disabled={serverState.submitting}
					>
					Enviar
				</button>

	            {serverState.status && (
	                <p className={!serverState.status.ok ? "errorMsg" : "okMsg"}>
	                	{serverState.status.msg}
	                </p>
	            )}				
			</form>
		</section>

		<BloqueContacto />

	  </Layout>
	);
};

export default ContactoPage